import { getUserSelector, isAccept } from '@/features/auth';
import { UserRole } from '@/types';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Link, Typography } from '@mui/material';
import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CarouselEntity } from '../../carousel.types';
import { useDrag, useDrop } from 'react-dnd';
import { DragItem, dropHover } from '@/utils';

interface CardProps {
  carousel: CarouselEntity;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onRemove: (id: string) => void;
}

export const CarouselCard: FC<CardProps> = ({ carousel, index, moveItem, onRemove }) => {
  const user = useSelector(getUserSelector);
  const ref = useRef<HTMLDivElement>(null);
  const [_, drop] = useDrop<DragItem, void>({
    accept: 'carousel',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: dropHover(ref, index, moveItem),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'carousel',
    item: () => {
      return { id: carousel.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Card ref={ref} sx={{ display: 'flex' }}>
      <CardMedia
        sx={{ width: '19rem' }}
        component="img"
        image={carousel.picture.url}
        alt={carousel.picture.originalName}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="h5" component="div">
            {carousel.name.ru}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {carousel.name.en}
          </Typography>
        </CardContent>
        <CardActions>
          {user && isAccept(user.role, UserRole.ADMIN) ? (
            <Button size="small" onClick={() => onRemove(carousel.id)}>
              Удалить
            </Button>
          ) : null}
          <Button component={Link} href={`/carousel/edit/${carousel.id}`} target="_blank" rel="noopener noreferrer">
            Редактировать
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
