import { Container, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Option } from '@/types';
import { CarouselCreatePayload, CarouselEntity } from './carousel.types';
import { updateCarouselAction } from './carousel.action';
import { CarouselForm } from './components';

export const CarouselEditPage: FC = () => {
  const dispatch = useDispatch();
  const { carouselId } = useParams();
  const [carousel, setCarousel] = useState<Option<CarouselEntity>>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      setIsLoading(true);
      const _carousel = await fetch(`${__API_URL__}/carousel/${carouselId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' as const,
        method: 'GET',
      }).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      });
      setIsLoading(false);
      setCarousel(_carousel);
    }
    if (carouselId) {
      fetchData();
    }
  }, [carouselId]);

  const handleFormSubmit = (data: CarouselCreatePayload): void => {
    if (carouselId) {
      dispatch(updateCarouselAction.started({ id: carouselId, data }));
    }
  };

  if (isLoading || !carousel) {
    return 'Loading ...';
  }

  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h1">
        Редактирование элемента карусели
      </Typography>
      <CarouselForm
        defaultValues={{
          nameRu: carousel.name.ru,
          nameEn: carousel.name.en,
          assetId: carousel.asset.id,
          picture: carousel.picture.url,
        }}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};
