import { CoreApi } from '@/api';
import { Observable } from 'rxjs';
import { HintCreatePayload, HintEntity } from './hint.types';

export class HintApi extends CoreApi {
  feed(): Observable<HintEntity[]> {
    return this.get('/hint');
  }

  update(data: HintCreatePayload): Observable<HintEntity> {
    return this.put(`/hint`, data);
  }
}
