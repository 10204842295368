import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Option } from '@/types';
import { Box, Button, Checkbox, Chip, FormControlLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { ImageUploader } from '@/components';
import { ModCreatePayload, ModType } from '../../mod.types';
import * as R from 'ramda';
import { ObjectSchema, number, object, string } from 'yup';
import { pictureArrayValidation, pictureValidation } from '@/utils';

interface CreateForm {
  nameRu: string;
  nameEn: string;
  descRu: string;
  descEn: string;
  videoUrlAndroidRu: string;
  videoUrlAndroidEn: string;
  videoUrlIosRu: string;
  videoUrlIosEn: string;
  version: string;
  cost: string;
  priority: string;
  isNew: boolean;
  isRewarded: boolean;
  isRewardedEng: boolean;
  tags: string[];
  pictures?: Option<(File | string)[]>;
  file?: FileList;
  generationKey: string;
}

interface ModFormProps {
  isEdit: boolean;
  defaultType: ModType;
  defaultValues: CreateForm;
  validationSchema?: ObjectSchema<CreateForm>;
  onSubmit: (data: ModCreatePayload) => void;
}

const validationSchemaDefault = object({
  nameRu: string().required(),
  nameEn: string().required(),
  descRu: string().required(),
  descEn: string().required(),
  videoUrlAndroidRu: string().url().required(),
  videoUrlAndroidEn: string().url().required(),
  videoUrlIosRu: string().url().required(),
  videoUrlIosEn: string().url().required(),
  version: string().required(),
  cost: number().required().min(0).integer(),
  pictures: pictureArrayValidation,
});

export const ModForm: FC<ModFormProps & { defaultFileName?: string; defaultFileUrl?: string }> = ({
  isEdit = false,
  defaultType = ModType.MOD,
  defaultValues,
  validationSchema = validationSchemaDefault,
  defaultFileName = '',
  defaultFileUrl = '',
  onSubmit,
}) => {
  const [tagItem, setTagItem] = useState('');
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CreateForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const [fileName, setFileName] = useState(defaultFileName);
  const [showDownloadButton, setShowDownloadButton] = useState(!!defaultFileUrl);
  const [type, setType] = useState(defaultType);
  console.log('errors: %o', errors);
  const values = getValues();
  const [defaultPictures] = useState(values.pictures ?? ['https://via.placeholder.com/400']);

  const handleFormSubmit = (data: CreateForm): void => {
    const {
      nameRu,
      nameEn,
      descRu,
      descEn,
      videoUrlAndroidRu,
      videoUrlAndroidEn,
      videoUrlIosRu,
      videoUrlIosEn,
      version,
      cost,
      isNew,
      isRewarded,
      isRewardedEng,
      tags,
      file,
      pictures,
      generationKey,
    } = data;
    const newData: ModCreatePayload = {
      type,
      name: { ru: nameRu, en: nameEn },
      desc: { ru: descRu, en: descEn },
      videoUrl: {
        android: { ru: videoUrlAndroidRu, en: videoUrlAndroidEn },
        ios: { ru: videoUrlIosRu, en: videoUrlIosEn },
      },
      version,
      cost,
      isNew,
      isRewarded,
      isRewardedEng,
      tags,
    };
    if (file && file[0] && type !== ModType.SEED) {
      newData.file = file[0];
    }
    if (generationKey && type === ModType.SEED) {
      newData.generationKey = generationKey;
    }
    if (pictures && Array.isArray(pictures) && pictures.every((picture) => picture instanceof File)) {
      newData.pictures = pictures;
    }
    onSubmit(newData);
  };

  const fileRegisterParams = register('file');
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={4}>
        {!isEdit ? (
          <Select
            labelId="type-label"
            id="type"
            label="Тип"
            value={type}
            onChange={(e) => setType(e.target.value as ModType)}
          >
            <MenuItem value={ModType.MOD}>Мод</MenuItem>
            <MenuItem value={ModType.SEED}>Сид</MenuItem>
            <MenuItem value={ModType.MAP}>Карта</MenuItem>
            <MenuItem value={ModType.SKIN}>Скин</MenuItem>
          </Select>
        ) : null}
        <TextField
          label="Название на русском"
          error={!!errors.nameRu?.message}
          helperText={errors.nameRu?.message}
          {...register('nameRu', { required: true })}
        />
        <TextField
          label="Название на английском"
          error={!!errors.nameEn?.message}
          helperText={errors.nameEn?.message}
          {...register('nameEn', { required: true })}
        />
        <TextField
          label="Описание на русском"
          error={!!errors.descRu?.message}
          helperText={errors.descRu?.message}
          multiline
          maxRows={4}
          {...register('descRu', { required: true })}
        />
        <TextField
          label="Описание на английском"
          error={!!errors.descEn?.message}
          helperText={errors.descEn?.message}
          multiline
          maxRows={4}
          {...register('descEn', { required: true })}
        />
        <TextField
          label="Ссылка на youtube android ru"
          error={!!errors.videoUrlAndroidRu?.message}
          helperText={errors.videoUrlAndroidRu?.message}
          {...register('videoUrlAndroidRu')}
        />
        <TextField
          label="Ссылка на youtube android en"
          error={!!errors.videoUrlAndroidEn?.message}
          helperText={errors.videoUrlAndroidEn?.message}
          {...register('videoUrlAndroidEn')}
        />
        <TextField
          label="Ссылка на youtube ios ru"
          error={!!errors.videoUrlIosRu?.message}
          helperText={errors.videoUrlIosRu?.message}
          {...register('videoUrlIosRu')}
        />
        <TextField
          label="Ссылка на youtube ios en"
          error={!!errors.videoUrlIosEn?.message}
          helperText={errors.videoUrlIosEn?.message}
          {...register('videoUrlIosEn')}
        />
        <TextField
          label="Версия"
          error={!!errors.version?.message}
          helperText={errors.version?.message}
          {...register('version')}
        />
        <TextField
          label="Цена"
          error={!!errors.cost?.message}
          helperText={errors.cost?.message}
          {...register('cost')}
        />
        <Controller
          name="isNew"
          control={control}
          render={({ field }) => (
            <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} />} label="Новый" />
          )}
        />
        <Controller
          name="isRewarded"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} onChange={field.onChange} />}
              label="Награжден"
            />
          )}
        />
        <Controller
          name="isRewardedEng"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} onChange={field.onChange} />}
              label="Награжден en"
            />
          )}
        />
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <Box>
              <TextField label="Тэги" value={tagItem} onChange={(e) => setTagItem(e.target.value)} />
              <Button onClick={() => field.onChange([...field.value, tagItem])}>Добавить тэг</Button>
              {field.value.map((tag, i) => (
                <Chip key={i} label={tag} onDelete={() => field.onChange(R.remove(i, 1, field.value))} />
              ))}
            </Box>
          )}
        />
        <Controller
          name="pictures"
          control={control}
          render={({ field }) => (
            <ImageUploader
              ref={field.ref}
              error={errors.pictures?.message}
              title="Картинка"
              images={defaultPictures}
              onChange={field.onChange}
            />
          )}
        />
        {type === ModType.SEED ? (
          <TextField label="Ключ генерации" {...register('generationKey')} />
        ) : (
          <div>
            <div style={{ marginLeft: '4px' }}>Файл мода</div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
              <label
                style={{
                  padding: '12px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'start',
                  width: 'max-content',
                  borderRadius: '4px',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                }}
                htmlFor="file"
              >
                {!fileName ? 'Загрузить файл' : fileName}
              </label>
              {showDownloadButton && (
                <a href={defaultFileUrl}>
                  <FileDownload color="primary" sx={{ margin: '4px', cursor: 'pointer' }} />
                </a>
              )}
              <input
                style={{ display: 'none' }}
                type="file"
                id="file"
                {...{
                  ...fileRegisterParams,
                  onChange(ev: { target: any; type?: any }) {
                    setFileName(ev.target.files[0].name);
                    setShowDownloadButton(false);
                    fileRegisterParams.onChange(ev);
                  },
                }}
              />
            </div>
          </div>
        )}
        <Button type="submit" onClick={handleSubmit(handleFormSubmit)}>
          {isEdit ? 'Сохранить' : 'Создать'}
        </Button>
      </Stack>
    </form>
  );
};
