import { Option, UserRole } from '@/types';
import { Add } from '@mui/icons-material';
import {
  Container,
  DialogContentText,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserListItem } from './components';
import { deleteUserAction, searchUsersAction } from './user.action';
import { getUsersSelector } from './user.selector';
import { ModalRemove } from '@/components';
import * as R from 'ramda';

interface UserListPageProps {
  role: UserRole[];
  title: string;
}

export const UserListPage: FC<UserListPageProps> = ({ role, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector(getUsersSelector);
  const [removeId, setRemoveId] = useState<Option<string>>(null);

  useEffect(() => {
    dispatch(searchUsersAction.started({ role }));
  }, [role]);

  const handleRemove = () => {
    if (removeId) {
      dispatch(deleteUserAction.started(removeId));
      setRemoveId(null);
    }
  };

  const toRemoveUserEmail = removeId ? users.find(R.propEq('id', removeId))?.email ?? '' : '';

  return (
    <Container>
      <Typography variant="h2">{title}</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Почта</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Ссылка для регистрации</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <UserListItem key={user.id} user={user} onRemove={setRemoveId} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        sx={{ position: 'fixed', bottom: '4rem', right: '4rem' }}
        size="large"
        color="primary"
        onClick={() => navigate('/user/create')}
      >
        <Add />
      </Fab>
      <ModalRemove
        title="Вы уверены что хотите удалить пользователя?"
        isOpen={removeId !== null}
        onClose={() => setRemoveId(null)}
        onRemove={handleRemove}
      >
        <DialogContentText id="alert-dialog-description">{toRemoveUserEmail}</DialogContentText>
      </ModalRemove>
    </Container>
  );
};
