import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { CarouselCard } from '../card';
import { RemoveModal } from '../remove-modal';
import { feedCarouselSelector } from '../../carousel.selector';
import { removeByIdCarouselAction, updateSortedCarouselAction } from '../../carousel.action';

interface CarouselListProps {}

const Root = styled(Box)({
  // maxWidth: '40rem',
});

export const CarouselList: FC<CarouselListProps> = () => {
  const dispatch = useDispatch();
  const [removeId, setRemoveId] = useState<string | null>(null);
  const carouselList = useSelector(feedCarouselSelector);
  const [list, setList] = useState(carouselList);
  useEffect(() => {
    setList(carouselList);
  }, [carouselList]);

  const handleRemove = (): void => {
    if (removeId) {
      dispatch(removeByIdCarouselAction.started(removeId));
      setList(list.filter(({ id }) => id !== removeId));
      setRemoveId(null);
    }
  };

  const updateSorted = (): void => {
    dispatch(updateSortedCarouselAction.started({ ids: list.map(R.prop('id')) }));
  };

  const moveItem = (dragIndex: number, hoverIndex: number): void => {
    setList(R.move(dragIndex, hoverIndex));
  };

  return (
    <Root>
      <Button onClick={updateSorted}>Сохранить сортировку</Button>
      {list.map((carousel, index) => (
        <CarouselCard key={carousel.id} index={index} carousel={carousel} onRemove={setRemoveId} moveItem={moveItem} />
      ))}
      {removeId ? (
        <RemoveModal isOpen={!!removeId} id={removeId} onClose={() => setRemoveId(null)} onRemove={handleRemove} />
      ) : null}
    </Root>
  );
};
