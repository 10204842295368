import { createSelector } from 'reselect';
import { HintState } from './hint.types';
import { ModType } from '../mod/mod.types';

export const hintSelector = <T extends { hint: HintState }>(state: T) => state.hint;

export const getByTypeHintSelector = createSelector(
  [hintSelector, (_, type: ModType) => type],
  ({ record }, type) => record[type],
);
