import { createAsyncSingleLoadingEpic } from '@ro-loading';
import { createAsyncEpic, ofActions } from '@tsfsa-ro';
import { combineEpics } from 'redux-observable';
import {
  createCompilationAction,
  feedCompilationAction,
  removeByIdCompilationAction,
  updateCompilationAction,
  updateSortedCompilationAction,
} from './compilation.action';
import { Epic } from '@/types';
import { filter } from 'rxjs';

const feedCompilationEpic = createAsyncEpic(feedCompilationAction, ({ compilationApi }) =>
  compilationApi.feed.bind(compilationApi),
);
const createCompilationEpic = createAsyncEpic(createCompilationAction, ({ compilationApi }) =>
  compilationApi.create.bind(compilationApi),
);
const updateCompilationEpic = createAsyncEpic(updateCompilationAction, ({ compilationApi }) =>
  compilationApi.update.bind(compilationApi),
);
const updateSortedCompilationEpic = createAsyncEpic(updateSortedCompilationAction, ({ compilationApi }) =>
  compilationApi.updateSorted.bind(compilationApi),
);
const removeByIdCompilationEpic = createAsyncEpic(removeByIdCompilationAction, ({ compilationApi }) =>
  compilationApi.removeById.bind(compilationApi),
);

const navigateToListEpic: Epic = (action$, _, { history }) =>
  action$.pipe(
    ofActions(createCompilationAction.done, updateCompilationAction.done),
    filter(() => {
      history.replace('/compilation');
      return false;
    }),
  );

const sortedFeedCompilationLoadingEpic = createAsyncSingleLoadingEpic(feedCompilationAction, 'feedCompilation');
const createCompilationLoadingEpic = createAsyncSingleLoadingEpic(createCompilationAction, 'createCompilation');

export const compilationEpic = combineEpics(
  feedCompilationEpic,
  createCompilationEpic,
  updateCompilationEpic,
  updateSortedCompilationEpic,
  removeByIdCompilationEpic,

  navigateToListEpic,

  sortedFeedCompilationLoadingEpic,
  createCompilationLoadingEpic,
);
