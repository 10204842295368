import { Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { CompilationCreatePayload } from './compilation.types';
import { createCompilationAction } from './compilation.action';
import { CompilationForm } from './components';

export const CompilationCreatePage: FC = () => {
  const dispatch = useDispatch();

  const handleFormSubmit = (data: CompilationCreatePayload): void => {
    console.log('data: %o', data);
    dispatch(createCompilationAction.started(data));
  };

  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h1">
        Создание новой подборки
      </Typography>
      <CompilationForm
        defaultValues={{
          type: '',
          nameRu: '',
          nameEn: '',
          assetIds: [],
        }}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};
