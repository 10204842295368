import { CoreApi } from '@/api';
import { Observable } from 'rxjs';
import {
  CompilationCreatePayload,
  CompilationEntity,
  CompilationUpdatePayload,
  SortedUpdateCompilationPayload,
} from './compilation.types';

export class CompilationApi extends CoreApi {
  getById(id: string): Observable<CompilationEntity> {
    return this.get(`/compilation/${id}`);
  }

  removeById(id: string): Observable<void> {
    return this.delete(`/compilation/${id}`);
  }

  create(body: CompilationCreatePayload): Observable<CompilationEntity> {
    return this.post('/compilation', body);
  }

  update({ id, data }: CompilationUpdatePayload): Observable<CompilationEntity> {
    return this.put(`/compilation/${id}`, data);
  }

  feed(): Observable<CompilationEntity[]> {
    return this.get('/compilation');
  }

  updateSorted(data: SortedUpdateCompilationPayload): Observable<CompilationEntity[]> {
    return this.put(`/compilation`, data);
  }
}
