import { combineEpics } from 'redux-observable';
import { map } from 'rxjs';
import * as R from 'ramda';
import { Option } from '@/types';
import { feedHintAction, updateHintAction } from './hint.action';
import { createAsyncEpic } from '@tsfsa-ro';
import { ModType } from '../mod/mod.types';
import { HintEntity } from './hint.types';
import { createAsyncSingleLoadingEpic } from '@ro-loading';

const findHint = (type: ModType, list: HintEntity[]): Option<HintEntity> =>
  R.find(R.propEq('type', type), list) ?? null;

const feedHintEpic = createAsyncEpic(
  feedHintAction,
  ({ hintApi }) =>
    () =>
      hintApi.feed().pipe(
        map((data) => {
          return {
            [ModType.MOD]: findHint(ModType.MOD, data),
            [ModType.SKIN]: findHint(ModType.SKIN, data),
            [ModType.SEED]: findHint(ModType.SEED, data),
            [ModType.MAP]: findHint(ModType.MAP, data),
          };
        }),
      ),
);
const updateHintEpic = createAsyncEpic(updateHintAction, ({ hintApi }) => hintApi.update.bind(hintApi));

const feedHintLoadingEpic = createAsyncSingleLoadingEpic(feedHintAction, 'feedHint');
const updateHintLoadingEpic = createAsyncSingleLoadingEpic(updateHintAction, 'updateHint');

export const hintEpic = combineEpics(
  feedHintEpic,
  updateHintEpic,

  feedHintLoadingEpic,
  updateHintLoadingEpic,
);
