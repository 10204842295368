import { CoreApi, toFormData } from '@/api';
import { Observable } from 'rxjs';
import {
  CarouselCreatePayload,
  CarouselEntity,
  CarouselUpdatePayload,
  SortedUpdateCarouselPayload,
} from './carousel.types';

export class CarouselApi extends CoreApi {
  getById(id: string): Observable<CarouselEntity> {
    return this.get(`/carousel/${id}`);
  }

  removeById(id: string): Observable<void> {
    return this.delete(`/carousel/${id}`);
  }

  create(body: CarouselCreatePayload): Observable<CarouselEntity> {
    return this.postFiles('/carousel', toFormData(body, ['picture']));
  }

  update({ id, data }: CarouselUpdatePayload): Observable<CarouselEntity> {
    return this.putFiles(`/carousel/${id}`, toFormData(data, ['picture']));
  }

  feed(): Observable<CarouselEntity[]> {
    return this.get('/carousel');
  }

  updateSorted(data: SortedUpdateCarouselPayload): Observable<CarouselEntity[]> {
    return this.put(`/carousel`, data);
  }
}
