import { createSelector } from 'reselect';
import { MemeState } from './meme.types';

export const memeSelector = <T extends { meme: MemeState }>(state: T) => state.meme;

export const getByIdMemeSelector = createSelector(
  [memeSelector, (_, id: string) => id],
  ({ record }, id) => record[id],
);
export const getSortedIdsMemeSelector = createSelector(memeSelector, ({ sortedIds }) => sortedIds);
