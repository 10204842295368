import { Box, CircularProgress, Container, Fab, Link, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { getSingleLoadingSelector } from '@ro-loading';
import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { feedCarouselAction } from './carousel.action';
import { CarouselList } from './components';

interface CarouselListPageProps {}

export const CarouselListPage: FC<CarouselListPageProps> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getSingleLoadingSelector(state, 'feedCarousel'));

  useEffect(() => {
    dispatch(feedCarouselAction.started());
  }, []);

  return (
    <Container sx={{ position: 'relative' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h2">
        Карусель
      </Typography>
      {isLoading ? (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <CarouselList />
      )}
      <Fab
        sx={{ position: 'fixed', bottom: '4rem', right: '4rem', zIndex: 2000 }}
        component={Link}
        href="/carousel/create"
        target="_blank"
        rel="noopener noreferrer"
        size="large"
        color="primary"
      >
        <Add />
      </Fab>
    </Container>
  );
};
