import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import * as R from 'ramda';
import { ObjectSchema, object, string } from 'yup';

import { getFeedModSelector } from '@/features/mod/mod.selector';
import { CompilationCreatePayload } from '../../compilation.types';
import { AddonItem } from './addon-item';

interface CreateForm {
  type: string;
  nameRu: string;
  nameEn: string;
  assetIds: string[];
}

interface CompilationFormProps {
  defaultValues: CreateForm;
  validationSchema?: ObjectSchema<CreateForm>;
  isEdit?: boolean;
  onSubmit: (data: CompilationCreatePayload) => void;
}

const validationSchemaDefault = object({
  type: string().required(),
  nameRu: string().required(),
  nameEn: string().required(),
});

export const CompilationForm: FC<CompilationFormProps> = ({
  defaultValues,
  validationSchema = validationSchemaDefault,
  isEdit = false,
  onSubmit,
}) => {
  console.log('defaultValues: %o', defaultValues);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const mods = useSelector(getFeedModSelector);
  console.log('errors: %o', errors);

  const handleFormSubmit = (data: CreateForm): void => {
    const { type, nameRu, nameEn, assetIds } = data;
    const newData: Partial<CompilationCreatePayload> = {
      type,
      name: { ru: nameRu, en: nameEn },
      assetIds,
    };
    onSubmit(newData as CompilationCreatePayload);
  };

  const handleAddAddon = (value: string[], onChange: (...event: any[]) => void) => (e: SelectChangeEvent) => {
    if (e.target.value) {
      onChange(R.append(e.target.value, value));
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={4}>
        <TextField label="Тип" error={!!errors.type?.message} helperText={errors.type?.message} {...register('type')} />
        <TextField
          label="Имя на русском"
          error={!!errors.nameRu?.message}
          helperText={errors.nameRu?.message}
          {...register('nameRu')}
        />
        <TextField
          label="Имя на английском"
          error={!!errors.nameEn?.message}
          helperText={errors.nameEn?.message}
          {...register('nameEn')}
        />
        <Controller
          name="assetIds"
          control={control}
          render={({ field }) => (
            <Stack spacing={2}>
              {field.value.map((addonId, index) => (
                <AddonItem
                  key={addonId}
                  addonId={addonId}
                  index={index}
                  moveItem={(dragIndex, hoverIndex) => field.onChange(R.move(dragIndex, hoverIndex, field.value))}
                  onRemove={() => field.onChange(R.filter((id) => id !== addonId, field.value))}
                />
              ))}
              <FormControl>
                <InputLabel id="add-addon-label">Добавить аддон</InputLabel>
                <Select
                  labelId="add-addon-label"
                  id="add-addon"
                  label="Добавить аддон"
                  value=""
                  onChange={handleAddAddon(field.value, field.onChange)}
                >
                  <MenuItem value="">Не выбрано</MenuItem>
                  {mods.map((mod) => (
                    <MenuItem key={mod.id} value={mod.id}>
                      {mod.name.ru}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
        />
        <Button type="submit" onClick={handleSubmit(handleFormSubmit)}>
          {isEdit ? 'Сохранить' : 'Создать'}
        </Button>
      </Stack>
    </form>
  );
};
