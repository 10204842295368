import { LangEntity, Option, PictureEntity } from '@/types';
import { ModEntity, ModType } from '../mod/mod.types';

export interface MemeEntity {
  readonly id: string;
  // readonly type: ModType;
  readonly desc: LangEntity;
  readonly likes: number;
  readonly haveAdv: boolean;
  readonly asset?: ModEntity;
  readonly downloadUrl?: string;
  readonly picture: PictureEntity;
}

export interface MemeCreatePayload {
  // readonly type: ModType;
  readonly desc: LangEntity;
  readonly haveAdv: boolean;
  itemId?: string;
  downloadUrl?: string;
  picture?: File;
}

export interface MemeUpdatePayload {
  id: string;
  data: MemeCreatePayload;
}

export interface SortedMemeResult {
  [ModType.MOD]: MemeEntity[];
}

export interface SortedMemeIds {
  [ModType.MOD]: string[];
}

export interface SortedFeedMemeDone {
  sortedIds: SortedMemeIds;
  record: Record<string, MemeEntity>;
}

export interface SortedUpdateMemePayload {
  memeIds: string[];
}

export interface MemeState {
  sortedIds: SortedMemeIds;
  record: Record<string, MemeEntity>;
}

export enum MemeDownloadType {
  NONE = "none",
  URL = "url",
  ASSET = "asset"
}