import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ModalRemove } from '@/components';
import { getByIdMemeSelector } from '../../meme.selector';

interface RemoveModalProps {
  isOpen: boolean;
  memeId: string;
  onClose: () => void;
  onRemove: () => void;
}

export const RemoveModal: FC<RemoveModalProps> = ({ isOpen, memeId, onClose, onRemove }) => {
  const meme = useSelector((state) => getByIdMemeSelector(state, memeId));

  return (
    <ModalRemove isOpen={isOpen} title="Вы уверены что хотите удалить мем?" onRemove={onRemove} onClose={onClose}>
      <DialogContentText id="alert-dialog-description">{meme.desc.ru}</DialogContentText>
    </ModalRemove>
  );
};
