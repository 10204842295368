import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ModType } from '../mod/mod.types';
import { HintState } from './hint.types';
import { feedHintAction, updateHintAction } from './hint.action';

const initialState: HintState = {
  record: {
    [ModType.MOD]: null,
    [ModType.SEED]: null,
    [ModType.SKIN]: null,
    [ModType.MAP]: null,
  },
};

export const hintReducer = reducerWithInitialState(initialState)
  .case(feedHintAction.done, (state, { result }) => ({ ...state, record: result }))
  .case(updateHintAction.done, (state, { result }) => ({
    ...state,
    record: { ...state.record, [result.type]: result },
  }));
