import { authEpics, homeEpic, modEpic, userEpic } from '@/features';
import { carouselEpic } from '@/features/carousel/carousel.epics';
import { compilationEpic } from '@/features/compilation/compilation.epics';
import { hintEpic } from '@/features/hint/hint.epic';
import { memeEpic } from '@/features/meme/meme.epics';
import { combineEpics } from 'redux-observable';

export const rootEpic = combineEpics(
  authEpics,
  homeEpic,
  userEpic,
  modEpic,
  hintEpic,
  memeEpic,
  carouselEpic,
  compilationEpic,
);
