import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { getFeedModByTypeSelector } from '@/features/mod/mod.selector';

import { Option } from '@/types';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { ImageUploader } from '@/components';
import { ObjectSchema, object, string, bool } from 'yup';
import { MemeCreatePayload, MemeDownloadType } from '../../meme.types';
import { ModType } from '@/features/mod/mod.types';
import { pictureValidation } from '@/utils';

interface CreateForm {
  descRu: string;
  descEn: string;
  haveAdv: boolean;
  itemId?: string;
  downloadUrl?: string;
  picture?: Option<File | string>;
}

interface MemeFormProps {
  defaultValues: CreateForm;
  validationSchema?: ObjectSchema<CreateForm>;
  onSubmit: (data: MemeCreatePayload) => void;
}

function useFilePreview(file: Option<File | string>): string {
  const [url, setUrl] = useState<string>('https://via.placeholder.com/400');
  useEffect(() => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setUrl(fileReader.result as string);
    };
    if (file && typeof file !== 'string') {
      fileReader.readAsDataURL(file);
    }
  }, [file]);
  if (typeof file === 'string') {
    return file;
  }
  return url;
}

const validationSchemaDefault = object({
  descRu: string().required(),
  descEn: string().required(),
  itemId: string(),
  downloadUrl: string().url(),
  picture: pictureValidation,
});

export const MemeForm: FC<MemeFormProps> = ({
  defaultValues,
  validationSchema = validationSchemaDefault,
  onSubmit,
}) => {
  console.log('defaultValues: %o', defaultValues);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CreateForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const [type, setType] = useState<ModType>(ModType.MOD);
  const mods = useSelector((state) => getFeedModByTypeSelector(state, type));
  console.log('errors: %o', errors);
  const values = getValues();
  const [downloadType, setDownloadType] = useState<MemeDownloadType>(
    values.itemId ? MemeDownloadType.ASSET : values.downloadUrl ? MemeDownloadType.URL : MemeDownloadType.NONE,
  );
  const pictureUrl = useFilePreview(values.picture);

  const handleFormSubmit = (data: CreateForm): void => {
    const { descRu, descEn, haveAdv, itemId, downloadUrl, picture } = data;
    const newData: MemeCreatePayload = {
      // type,
      haveAdv,
      desc: { ru: descRu, en: descEn },
    };
    switch (downloadType) {
      case MemeDownloadType.ASSET:
        newData.itemId = itemId;
        break;
      case MemeDownloadType.URL:
        newData.downloadUrl = downloadUrl;
        break;
    }
    if (picture && picture instanceof File) {
      newData.picture = picture;
    }
    onSubmit(newData);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={4}>
        <TextField
          label="Описание на русском"
          error={!!errors.descRu?.message}
          helperText={errors.descRu?.message}
          multiline
          maxRows={4}
          {...register('descRu', { required: true })}
        />
        <TextField
          label="Описание на английском"
          error={!!errors.descEn?.message}
          helperText={errors.descEn?.message}
          multiline
          maxRows={4}
          {...register('descEn', { required: true })}
        />
        <Controller
          name="haveAdv"
          control={control}
          render={({ field }) => (
            <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} />} label="Have Adv" />
          )}
        />
        <FormControl>
          <InputLabel id="download-type-label">Тип загрузки</InputLabel>
          <Select
            labelId="download-type-label"
            id="download-type"
            label="Тип загрузки"
            value={downloadType}
            onChange={(ev) => setDownloadType(ev.target.value as MemeDownloadType)}
          >
            <MenuItem value={MemeDownloadType.NONE}>Нет</MenuItem>
            <MenuItem value={MemeDownloadType.ASSET}>Аддон</MenuItem>
            <MenuItem value={MemeDownloadType.URL}>Ссылка</MenuItem>
          </Select>
          <FormHelperText>{errors.itemId?.message}</FormHelperText>
        </FormControl>
        {downloadType != MemeDownloadType.NONE &&
          (downloadType == MemeDownloadType.ASSET ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Select
                labelId="type-label"
                id="type"
                label="Тип аддона"
                value={type}
                onChange={(e) => setType(e.target.value as ModType)}
              >
                <MenuItem value={ModType.MOD}>Мод</MenuItem>
                <MenuItem value={ModType.SEED}>Сид</MenuItem>
                <MenuItem value={ModType.MAP}>Карта</MenuItem>
                <MenuItem value={ModType.SKIN}>Скин</MenuItem>
              </Select>
              <Controller
                name="itemId"
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ marginTop: '32px' }} error={!!errors.itemId?.message}>
                    <InputLabel id="type-label">Аддон</InputLabel>
                    <Select labelId="type-label" id="type" label="Аддон" {...field}>
                      <MenuItem value={undefined}>Не выбрано</MenuItem>
                      {mods.map((mod) => (
                        <MenuItem key={mod.id} value={mod.id}>
                          {mod.name.ru}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.itemId?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </div>
          ) : (
            <TextField
              label="Ссылка на загрузку"
              error={!!errors.downloadUrl?.message}
              helperText={errors.downloadUrl?.message}
              {...register('downloadUrl', { required: false })}
            />
          ))}

        <Controller
          name="picture"
          control={control}
          render={({ field }) => (
            <ImageUploader
              error={errors.picture?.message}
              title="Картинка"
              images={[pictureUrl]}
              onChange={(arr) => field.onChange(arr[0])}
              limit={1}
            />
          )}
        />
        <Button type="submit" onClick={handleSubmit(handleFormSubmit)}>
          Создать
        </Button>
      </Stack>
    </form>
  );
};
