import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { byIdModSelector } from '../../mod.selector';
import { ModalRemove } from '@/components';

interface RemoveModalProps {
  isOpen: boolean;
  modId: string;
  onClose: () => void;
  onRemove: () => void;
}

export const RemoveModal: FC<RemoveModalProps> = ({ isOpen, modId, onClose, onRemove }) => {
  const mod = useSelector((state) => byIdModSelector(state, modId));

  return (
    <ModalRemove isOpen={isOpen} title="Вы уверены что хотите удалить мод?" onRemove={onRemove} onClose={onClose}>
      <DialogContentText id="alert-dialog-description">{mod.name.ru}</DialogContentText>
    </ModalRemove>
  );
};
