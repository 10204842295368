import { mixed } from 'yup';

export const pictureValidation = mixed().test('file', 'The picture is required', (value) => {
  if (value instanceof File || (value && typeof value === 'string' && value.length)) {
    return true;
  }
  return false;
});

export const pictureArrayValidation = mixed().test('file', 'The picture is required', (arr) => {
  return !!arr && Array.isArray(arr) && arr.every((value) => {
    if (value instanceof File || (value && typeof value === 'string' && value.length)) {
      return true;
    }
    return false;
  });
});
