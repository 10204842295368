import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ModalRemove } from '@/components';
import { getByIdCarouselSelector } from '../../carousel.selector';

interface RemoveModalProps {
  isOpen: boolean;
  id: string;
  onClose: () => void;
  onRemove: () => void;
}

export const RemoveModal: FC<RemoveModalProps> = ({ isOpen, id, onClose, onRemove }) => {
  const carousel = useSelector((state) => getByIdCarouselSelector(state, id));

  return (
    <ModalRemove isOpen={isOpen} title="Вы уверены что хотите удалить мем?" onRemove={onRemove} onClose={onClose}>
      <DialogContentText id="alert-dialog-description">{carousel?.name.ru ?? id}</DialogContentText>
    </ModalRemove>
  );
};
