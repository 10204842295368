import { Container, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Option } from '@/types';
import { MemeForm } from './components';
import { getByIdModAction, updateModAction } from './mod.action';
import { getByIdModSelector } from './mod.selector';
import { MemeCreatePayload, MemeEntity } from './meme.types';
import { updateMemeAction } from './meme.action';
import { useSelector } from 'react-redux';
import { getFeedModByTypeSelector } from '../mod/mod.selector';

export const MemeEditPage: FC = () => {
  const dispatch = useDispatch();
  const { memeId } = useParams();
  const [meme, setMeme] = useState<Option<MemeEntity>>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      setIsLoading(true);
      const _meme = await fetch(`${__API_URL__}/meme/${memeId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' as const,
        method: 'GET',
      }).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      });
      console.log('meme: %o', _meme);
      setIsLoading(false);
      setMeme(_meme);
    }
    console.log('memeId: %o', memeId);
    if (memeId) {
      fetchData();
    }
  }, [memeId]);

  const handleFormSubmit = (data: MemeCreatePayload): void => {
    if (memeId) {
      dispatch(updateMemeAction.started({ id: memeId, data }));
    }
  };

  if (isLoading || !meme) {
    return 'Loading ...';
  }

  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h1">
        Редактирование мема
      </Typography>
      <MemeForm
        defaultValues={{
          descRu: meme.desc.ru,
          descEn: meme.desc.en,
          haveAdv: meme.haveAdv,
          itemId: meme.asset?.id,
          downloadUrl: meme.downloadUrl,
          picture: meme.picture.url,
        }}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};
