import { useIsAccept } from '@/features/auth';
import { UserRole } from '@/types';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Link,
  Typography,
} from '@mui/material';
import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CompilationEntity } from '../../compilation.types';
import { useDrag, useDrop } from 'react-dnd';
import { DragItem, dropHover } from '@/utils';
import { ExpandMore } from '@mui/icons-material';
import { getByIdCompilationSelector } from '../../compilation.selector';

interface CardProps {
  compilationId: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onRemove: (id: string) => void;
}

export const CompilationCard: FC<CardProps> = ({ compilationId, index, moveItem, onRemove }) => {
  const isAccept = useIsAccept(UserRole.ADMIN);
  const compilation = useSelector((state) => getByIdCompilationSelector(state, compilationId));
  const ref = useRef<HTMLDivElement>(null);
  const [_, drop] = useDrop<DragItem, void>({
    accept: 'compilation',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: dropHover(ref, index, moveItem),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'compilation',
    item: () => {
      return { id: compilation.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Accordion ref={ref}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${compilation.id}-content`}
        id={`${compilation.id}-header`}
      >
        <Typography>
          {compilation.name.ru} ({compilation.name.en})
        </Typography>
        <Typography color="gray">&nbsp; Тип: {compilation.type}</Typography>
      </AccordionSummary>
      <AccordionActions>
        {isAccept ? (
          <Button size="small" onClick={() => onRemove(compilation.id)}>
            Удалить
          </Button>
        ) : null}
        <Button component={Link} href={`/compilation/edit/${compilation.id}`} target="_blank" rel="noopener noreferrer">
          Редактировать
        </Button>
      </AccordionActions>
      <AccordionDetails>
        {compilation.assets.map((asset) => {
          return (
            <Card key={asset.id} sx={{ display: 'flex' }}>
              <CardMedia
                sx={{ width: '19rem' }}
                component="img"
                image={asset.pictures[0].url}
                alt={asset.pictures[0].originalName}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="h5" component="div">
                    {asset.name.ru}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {asset.name.en}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
