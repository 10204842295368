import { LangEntity } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

interface CreateForm {
  nameRu: string;
  nameEn: string;
}

interface UpdateModalProps {
  defaultValues?: CreateForm;
  actionButton?: string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: LangEntity) => void;
}

const validationSchemaDefault = object({
  nameRu: string().required(),
  nameEn: string().required(),
});

export const UpdateModal: FC<UpdateModalProps> = ({
  defaultValues = {
    nameRu: '',
    nameEn: '',
  },
  actionButton = 'Создать',
  title = 'Добавить новую подсказку',
  isOpen,
  onClose,
  onSave,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateForm>({
    defaultValues,
    resolver: yupResolver(validationSchemaDefault),
  });

  const handleFormSubmit = ({ nameRu, nameEn }: CreateForm): void => {
    onSave({ ru: nameRu, en: nameEn });
    reset();
  };

  const handleClose = () => {
    reset();
    onClose();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-update-title"
      aria-describedby="modal-update-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <Stack spacing={4}>
            <TextField
              label="Подсказка Ru"
              error={!!errors.nameRu?.message}
              helperText={errors.nameRu?.message}
              {...register('nameRu')}
            />
            <TextField
              label="Подсказка En"
              error={!!errors.nameEn?.message}
              helperText={errors.nameEn?.message}
              {...register('nameEn')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="outlined" color="error">
            {actionButton}
          </Button>
          <Button onClick={handleClose} autoFocus>
            Отмена
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
