import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { getFeedModByTypeSelector } from '@/features/mod/mod.selector';

import { Option } from '@/types';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { ImageUploader } from '@/components';
import * as R from 'ramda';
import { ObjectSchema, object, string } from 'yup';
import { ModType } from '@/features/mod/mod.types';
import { CarouselCreatePayload } from '../../carousel.types';
import { pictureValidation } from '@/utils';

interface CreateForm {
  nameRu: string;
  nameEn: string;
  assetId: string;
  picture?: Option<File | string>;
}

interface CarouselFormProps {
  defaultValues: CreateForm;
  validationSchema?: ObjectSchema<CreateForm>;
  onSubmit: (data: CarouselCreatePayload) => void;
}

function useFilePreview(file: Option<File | string>): string {
  const [url, setUrl] = useState<string>('https://via.placeholder.com/400');
  useEffect(() => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setUrl(fileReader.result as string);
    };
    if (file && typeof file !== 'string') {
      fileReader.readAsDataURL(file);
    }
  }, [file]);
  if (typeof file === 'string') {
    return file;
  }
  return url;
}

const validationSchemaDefault = object({
  nameRu: string().required(),
  nameEn: string().required(),
  assetId: string().required(),
  picture: pictureValidation,
});

export const CarouselForm: FC<CarouselFormProps> = ({
  defaultValues,
  validationSchema = validationSchemaDefault,
  onSubmit,
}) => {
  console.log('defaultValues: %o', defaultValues);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CreateForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const [type, setType] = useState<ModType>(ModType.MOD);
  const mods = useSelector((state) => getFeedModByTypeSelector(state, type));
  console.log('errors: %o', errors);
  const values = getValues();
  const pictureUrl = useFilePreview(values.picture);

  const handleFormSubmit = (data: CreateForm): void => {
    const { nameRu, nameEn, assetId, picture } = data;
    const newData: Partial<CarouselCreatePayload> = {
      type,
      assetId,
      name: { ru: nameRu, en: nameEn },
    };
    if (picture && picture instanceof File) {
      newData.picture = picture;
    }
    onSubmit(newData as CarouselCreatePayload);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={4}>
        <Select
          labelId="type-label"
          id="type"
          label="Тип"
          value={type}
          onChange={(e) => setType(e.target.value as ModType)}
        >
          <MenuItem value={ModType.MOD}>Мод</MenuItem>
          <MenuItem value={ModType.SEED}>Сид</MenuItem>
          <MenuItem value={ModType.MAP}>Карта</MenuItem>
          <MenuItem value={ModType.SKIN}>Скин</MenuItem>
        </Select>
        <TextField
          label="Имя на русском"
          error={!!errors.nameRu?.message}
          helperText={errors.nameRu?.message}
          {...register('nameRu')}
        />
        <TextField
          label="Имя на английском"
          error={!!errors.nameEn?.message}
          helperText={errors.nameEn?.message}
          {...register('nameEn')}
        />
        <Controller
          name="assetId"
          control={control}
          render={({ field }) => (
            <FormControl error={!!errors.assetId?.message}>
              <InputLabel id="type-label">Аддон</InputLabel>
              <Select labelId="type-label" id="type" label="Аддон" {...field}>
                <MenuItem value={undefined}>Не выбрано</MenuItem>
                {mods.map((mod) => (
                  <MenuItem key={mod.id} value={mod.id}>
                    {mod.name.ru}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.assetId?.message}</FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="picture"
          control={control}
          render={({ field }) => (
            <ImageUploader
              ref={field.ref}
              error={errors.picture?.message}
              title="Картинка"
              images={[pictureUrl]}
              onChange={(arr) => field.onChange(arr[0])}
              limit={1}
            />
          )}
        />
        <Button type="submit" onClick={handleSubmit(handleFormSubmit)}>
          Создать
        </Button>
      </Stack>
    </form>
  );
};
