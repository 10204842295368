import { Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { CarouselCreatePayload } from './carousel.types';
import { createCarouselAction } from './carousel.action';
import { CarouselForm } from './components';

export const CarouselCreatePage: FC = () => {
  const dispatch = useDispatch();

  const handleFormSubmit = (data: CarouselCreatePayload): void => {
    console.log('data: %o', data);
    dispatch(createCarouselAction.started(data));
  };

  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h1">
        Создание нового элемента карусели
      </Typography>
      <CarouselForm
        defaultValues={{
          nameRu: '',
          nameEn: '',
          assetId: '',
          // picture: null,
        }}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};
