import { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { guard } from '../auth';
import { useDispatch } from 'react-redux';
import { feedModAction } from '../mod/mod.action';
import { CarouselListPage } from './carousel-list.page';
import { CarouselCreatePage } from './carousel-create.page';
import { CarouselEditPage } from './carousel-edit.page';

export const CarouselPage: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(feedModAction.started());
  }, []);
  return (
    <Routes>
      <Route index element={guard(<CarouselListPage />)} />
      <Route path="create" element={guard(<CarouselCreatePage />, { redirect: false })} />
      <Route path="edit/:carouselId" element={guard(<CarouselEditPage />, { redirect: false })} />
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
};
