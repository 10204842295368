import actionCreatorFactory from 'typescript-fsa';
import {
  CarouselCreatePayload,
  CarouselEntity,
  CarouselUpdatePayload,
  SortedUpdateCarouselPayload,
} from './carousel.types';

const actionCreator = actionCreatorFactory('[CAROUSEL]');

export const createCarouselAction = actionCreator.async<CarouselCreatePayload, CarouselEntity>('CREATE');
export const updateCarouselAction = actionCreator.async<CarouselUpdatePayload, CarouselEntity>('UPDATE');
export const feedCarouselAction = actionCreator.async<void, CarouselEntity[]>('FEED');
export const removeByIdCarouselAction = actionCreator.async<string, void>('REMOVE_BY_ID');
export const updateSortedCarouselAction = actionCreator.async<SortedUpdateCarouselPayload, CarouselEntity[]>(
  'UPDATE_SORTED',
);
