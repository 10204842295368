import { getUserSelector, isAccept } from '@/features/auth';
import { UserRole } from '@/types';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getByIdMemeSelector } from '../../meme.selector';

interface CardProps {
  memeId: string;
  onRemove: (id: string) => void;
}

export const MemeCard: FC<CardProps> = ({ memeId, onRemove }) => {
  const user = useSelector(getUserSelector);
  const meme = useSelector((state) => getByIdMemeSelector(state, memeId));

  return (
    <Card sx={{ display: 'flex' }}>
      <CardMedia sx={{ width: '19rem' }} component="img" image={meme.picture.url} alt={meme.picture.originalName} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="h5" component="div">
            {meme.desc.ru}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {meme.desc.en}
          </Typography>
        </CardContent>
        <CardActions>
          {user && isAccept(user.role, UserRole.ADMIN) ? (
            <Button size="small" onClick={() => onRemove(memeId)}>
              Удалить
            </Button>
          ) : null}
          <Button component={Link} href={`/meme/edit/${meme.id}`} target="_blank" rel="noopener noreferrer">
            Редактировать
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
