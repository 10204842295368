import styled from '@emotion/styled';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { CompilationCard } from '../card';
import { RemoveModal } from '../remove-modal';
import { feedIdsCompilationSelector } from '../../compilation.selector';
import { removeByIdCompilationAction, updateSortedCompilationAction } from '../../compilation.action';
import { DndCompilationList } from './dnd-list';

interface CompilationListProps {}

const Root = styled(Box)({
  // maxWidth: '40rem',
});

export const CompilationList: FC<CompilationListProps> = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [removeId, setRemoveId] = useState<string | null>(null);
  const compilationList = useSelector(feedIdsCompilationSelector);
  const [list, setList] = useState(compilationList);
  useEffect(() => {
    setList(compilationList);
  }, [compilationList]);

  const handleRemove = (): void => {
    if (removeId) {
      dispatch(removeByIdCompilationAction.started(removeId));
      setList(list.filter((id) => id !== removeId));
      setRemoveId(null);
    }
  };

  const handleTabChange = (_: SyntheticEvent, newTab: number): void => {
    setTab(newTab);
  };

  const updateSorted = (): void => {
    dispatch(updateSortedCompilationAction.started({ ids: list }));
  };

  const moveItem = (dragIndex: number, hoverIndex: number): void => {
    setList(R.move(dragIndex, hoverIndex));
  };

  return (
    <Root>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Карточки" />
          <Tab label="Таблица (для пересортировки)" />
        </Tabs>
      </Box>
      {tab === 0
        ? list.map((compilationId, index) => (
            <CompilationCard
              key={compilationId}
              index={index}
              compilationId={compilationId}
              onRemove={setRemoveId}
              moveItem={moveItem}
            />
          ))
        : null}
      {tab === 1 ? (
        <Box>
          <Button onClick={updateSorted}>Сохранить сортировку</Button>
          <DndCompilationList listIds={list} moveItem={moveItem} onRemove={setRemoveId} />
        </Box>
      ) : null}
      {removeId ? (
        <RemoveModal isOpen={!!removeId} id={removeId} onClose={() => setRemoveId(null)} onRemove={handleRemove} />
      ) : null}
    </Root>
  );
};
