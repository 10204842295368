import { Container, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import { Option } from '@/types';
import { CompilationCreatePayload, CompilationEntity } from './compilation.types';
import { updateCompilationAction } from './compilation.action';
import { CompilationForm } from './components';

export const CompilationEditPage: FC = () => {
  const dispatch = useDispatch();
  const { compilationId } = useParams();
  const [compilation, setCompilation] = useState<Option<CompilationEntity>>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      setIsLoading(true);
      const _compilation = await fetch(`${__API_URL__}/compilation/${compilationId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' as const,
        method: 'GET',
      }).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      });
      setIsLoading(false);
      setCompilation(_compilation);
    }
    if (compilationId) {
      fetchData();
    }
  }, [compilationId]);

  const handleFormSubmit = (data: CompilationCreatePayload): void => {
    if (compilationId) {
      dispatch(updateCompilationAction.started({ id: compilationId, data }));
    }
  };

  if (isLoading || !compilation) {
    return 'Loading ...';
  }

  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h1">
        Редактирование подборки {compilation.name.ru} ({compilation.type})
      </Typography>
      <CompilationForm
        isEdit
        defaultValues={{
          type: compilation.type,
          nameRu: compilation.name.ru,
          nameEn: compilation.name.en,
          assetIds: compilation.assets.map(R.prop('id')),
        }}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};
