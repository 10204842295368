import { createSelector } from 'reselect';
import { CarouselState } from './carousel.types';
import * as R from 'ramda';

export const carouselSelector = <T extends { carousel: CarouselState }>(state: T) => state.carousel;

export const feedCarouselSelector = createSelector(carouselSelector, ({ feed }) => feed);
export const getByIdCarouselSelector = createSelector([feedCarouselSelector, (_, id: string) => id], (feed, id) =>
  feed.find(R.propEq('id', id)),
);
