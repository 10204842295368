import { FC, useEffect, useState } from 'react';
import { ModType } from '../mod/mod.types';
import { DndHintListItem, UpdateModal } from './components';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  DialogContentText,
  Fab,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import { getByTypeHintSelector } from './hint.selector';
import { getSingleLoadingSelector } from '@ro-loading';
import { feedHintAction, updateHintAction } from './hint.action';
import * as R from 'ramda';
import { LangEntity, Option } from '@/types';
import { ModalRemove } from '@/components';

interface HintListPageProps {
  type: ModType;
}

export const HintListPage: FC<HintListPageProps> = ({ type }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getSingleLoadingSelector(state, 'feedHint'));
  const hint = useSelector((state) => getByTypeHintSelector(state, type));
  const [hintList, setHintList] = useState(hint?.hints ?? []);
  const [removeHint, setRemoveHint] = useState<Option<number>>(null);
  const [editHint, setEditHint] = useState<Option<number>>(null);
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    dispatch(feedHintAction.started());
  }, []);

  useEffect(() => {
    setHintList(hint?.hints ?? []);
  }, [hint?.hints]);

  const moveHint = (dragIndex: number, hoverIndex: number): void => {
    setHintList((data) => R.move(dragIndex, hoverIndex, data));
  };

  const handleRemove = (): void => {
    if (hint && removeHint !== null) {
      dispatch(updateHintAction.started({ ...hint, hints: R.remove(removeHint, 1, hintList) }));
    }
    setRemoveHint(null);
  };

  const updateSorted = (): void => {
    if (hint) {
      dispatch(updateHintAction.started({ ...hint, hints: hintList }));
    }
  };

  const handleEditSave = (editedHint: LangEntity): void => {
    if (hint && editHint) {
      dispatch(updateHintAction.started({ ...hint, hints: R.update(editHint, editedHint, hintList) }));
    }
    setEditHint(null);
  };

  const handleCreateSave = (newHint: LangEntity): void => {
    if (hint) {
      dispatch(updateHintAction.started({ ...hint, hints: R.append(newHint, hint.hints) }));
    } else {
      dispatch(updateHintAction.started({ type, hints: [newHint] }));
    }
    setIsCreate(false);
  };

  return (
    <Container sx={{ position: 'relative' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h2">
        Список подсказок для поиска {type}
      </Typography>
      <Stack spacing={4} direction="row">
        <Link href="/hint/mod">
          <Typography variant="body1">Моды</Typography>
        </Link>
        <Link href="/hint/seed">
          <Typography variant="body1">Сиды</Typography>
        </Link>
        <Link href="/hint/skin">
          <Typography variant="body1">Скины</Typography>
        </Link>
        <Link href="/hint/map">
          <Typography variant="body1">Карты</Typography>
        </Link>
      </Stack>
      {isLoading ? (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Button onClick={updateSorted}>Сохранить сортировку</Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Потяни меня</TableCell>
                  <TableCell>Подсказка</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hintList.map((hintText, index) => (
                  <DndHintListItem
                    key={hintText.id}
                    index={index}
                    hintText={hintText}
                    moveHint={moveHint}
                    onEdit={() => setEditHint(index)}
                    onRemove={() => setRemoveHint(index)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Fab
        sx={{ position: 'fixed', bottom: '4rem', right: '4rem', zIndex: 2000 }}
        size="large"
        color="primary"
        onClick={() => setIsCreate(true)}
      >
        <Add />
      </Fab>
      <ModalRemove
        title="Вы уверены что хотите удалить поисковую подсказку?"
        isOpen={removeHint !== null}
        onClose={() => setRemoveHint(null)}
        onRemove={handleRemove}
      >
        <DialogContentText id="alert-dialog-description">{removeHint}</DialogContentText>
      </ModalRemove>
      {editHint !== null ? (
        <UpdateModal
          defaultValues={{
            nameRu: hintList[editHint].ru,
            nameEn: hintList[editHint].en,
          }}
          actionButton="Сохранить"
          title="Редактировать подсказку"
          isOpen={editHint !== null}
          onClose={() => setEditHint(null)}
          onSave={handleEditSave}
        />
      ) : null}
      <UpdateModal isOpen={isCreate} onClose={() => setIsCreate(false)} onSave={handleCreateSave} />
    </Container>
  );
};
