import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { assocPath, omit } from 'ramda';
import { MemeState } from './meme.types';
import { ModType } from '../mod/mod.types';
import { removeByIdMemeAction, sortedFeedMemeAction, updateMemeAction } from './meme.action';

const initialState: MemeState = {
  record: {},
  sortedIds: {
    [ModType.MOD]: [],
    [ModType.SEED]: [],
    [ModType.SKIN]: [],
    [ModType.MAP]: [],
  },
};

export const memeReducer = reducerWithInitialState(initialState)
  .case(sortedFeedMemeAction.done, (state, { result }) => ({ ...state, ...result }))
  .case(updateMemeAction.done, (state, { result }) => assocPath(['record', result.id], result, state))
  .case(removeByIdMemeAction.done, (state, { params: modId }) => {
    const { type } = state.record[modId];
    const record = omit([modId], state.record);
    const sortedIds = {
      ...state.sortedIds,
      [type]: state.sortedIds[type].filter((id) => id !== modId),
    };
    return {
      ...state,
      record,
      sortedIds,
    };
  });
