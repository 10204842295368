import styled from '@emotion/styled';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { FC, SyntheticEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MemeCard } from '../card';
import { RemoveModal } from '../remove-modal';
import { DndMemeList } from './dnd-list';
import * as R from 'ramda';
import { ModType } from '@/features/mod/mod.types';
import { getSortedIdsMemeSelector } from '../../meme.selector';
import { removeByIdMemeAction, updateSortedMemeAction } from '../../meme.action';


const Root = styled(Box)({
  // maxWidth: '40rem',
});

export const MemeList: FC = ({ }) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [removeId, setRemoveId] = useState<string | null>(null);
  const memeIds = useSelector(getSortedIdsMemeSelector);
  const [list, setList] = useState(memeIds[ModType.MOD]);
  const handleRemove = (): void => {
    if (removeId) {
      dispatch(removeByIdMemeAction.started(removeId));
      setRemoveId(null);
      setList(list.filter((id) => id !== removeId));
    }
  };

  const handleTabChange = (_: SyntheticEvent, newTab: number): void => {
    setTab(newTab);
  };

  const updateSorted = (): void => {
    dispatch(updateSortedMemeAction.started({ memeIds: list }));
  };

  const moveItem = (dragIndex: number, hoverIndex: number): void => {
    setList((data) => R.move(dragIndex, hoverIndex, data));
  };

  return (
    <Root>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Карточки" />
          <Tab label="Таблица (для пересортировки)" />
        </Tabs>
      </Box>
      {tab === 0 ? list.map((modId) => <MemeCard key={modId} memeId={modId} onRemove={setRemoveId} />) : null}
      {tab === 1 ? (
        <Box>
          <Button onClick={updateSorted}>Сохранить сортировку</Button>
          <DndMemeList listIds={list} moveItem={moveItem} onRemove={setRemoveId} />
        </Box>
      ) : null}
      {removeId ? (
        <RemoveModal isOpen={!!removeId} memeId={removeId} onClose={() => setRemoveId(null)} onRemove={handleRemove} />
      ) : null}
    </Root>
  );
};
