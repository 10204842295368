import { CoreApi, toFormData } from '@/api';
import { Observable } from 'rxjs';
import {
  MemeCreatePayload,
  MemeEntity,
  MemeUpdatePayload,
  SortedMemeResult,
  SortedUpdateMemePayload,
} from './meme.types';

export class MemeApi extends CoreApi {
  getById(id: string): Observable<MemeEntity> {
    return this.get(`/meme/${id}`);
  }

  removeById(id: string): Observable<void> {
    return this.delete(`/meme/${id}`);
  }

  create(body: MemeCreatePayload): Observable<MemeEntity> {
    return this.postFiles('/meme', toFormData(body, ['picture']));
  }

  update({ id, data }: MemeUpdatePayload): Observable<MemeEntity> {
    return this.putFiles(`/meme/${id}`, toFormData(data, ['picture']));
  }

  sortedFeed(): Observable<SortedMemeResult> {
    return this.get('/meme/sorted');
  }

  updateSorted(data: SortedUpdateMemePayload): Observable<void> {
    return this.put(`/meme/sorted`, data);
  }
}
