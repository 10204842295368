import { createSelector } from 'reselect';
import { ModState, ModType } from './mod.types';
import * as R from 'ramda';

export const modSelector = <T extends { mod: ModState }>(state: T) => state.mod;

export const getFeedModSelector = createSelector(modSelector, ({ feed }) => feed);
export const getFeedModByTypeSelector = createSelector([modSelector, (_, type: ModType) => type], ({ feed }, type) =>
  feed.filter(R.propEq('type', type)),
);
export const getFeedByIdModSelector = createSelector([getFeedModSelector, (_, id: string) => id], (feed, id) =>
  R.find(R.propEq('id', id), feed),
);
export const getByIdModSelector = createSelector(modSelector, ({ byId }) => byId);
export const byIdModSelector = createSelector([modSelector, (_, id: string) => id], ({ record }, id) => record[id]);
export const getSortedIdsModSelector = createSelector(modSelector, ({ sortedIds }) => sortedIds);
