import { ReactNode } from 'react';
import { PatientRoute } from './components';
import { UserEntity, UserRole } from '@/types';
import { useSelector } from 'react-redux';
import { getUserSelector } from './auth.selectors';

interface Guard {
  redirect?: boolean;
  role?: UserRole;
}

const roleMap = {
  [UserRole.MANAGER]: 0,
  [UserRole.ADMIN]: 1,
  [UserRole.SUPER_ADMIN]: 2,
};

export const guard = (
  element: ReactNode,
  { redirect = true, role = UserRole.MANAGER }: Guard = { redirect: true, role: UserRole.MANAGER },
) => {
  return <PatientRoute element={element} redirect={redirect} role={role} />;
};

export function isAccept(userRole: UserRole, roleDestination: UserRole): boolean {
  return roleMap[userRole] >= roleMap[roleDestination];
}

export function useIsAccept(roleDestination: UserRole): boolean {
  const user = useSelector(getUserSelector);
  if (!user) {
    return false;
  }
  return isAccept(user.role, roleDestination);
}
