import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { assoc, filter, lensProp, over } from 'ramda';
import { CarouselEntity, CarouselState } from './carousel.types';
import {
  feedCarouselAction,
  removeByIdCarouselAction,
  updateCarouselAction,
  updateSortedCarouselAction,
} from './carousel.action';

const initialState: CarouselState = {
  feed: [],
};

export const carouselReducer = reducerWithInitialState(initialState)
  .case(feedCarouselAction.done, (state, { result }) => assoc('feed', result, state))
  .case(removeByIdCarouselAction.done, (state, { params: carouselId }) =>
    over(
      lensProp('feed'),
      filter((item: CarouselEntity) => item.id !== carouselId),
      state,
    ),
  )
  .case(updateCarouselAction.done, (state, { result }) =>
    assoc(
      'feed',
      state.feed.map((item) => (item.id === result.id ? result : item)),
      state,
    ),
  )
  .case(updateSortedCarouselAction.done, (state, { result }) => assoc('feed', result, state));
