import { createSelector } from 'reselect';
import { CompilationState } from './compilation.types';

export function compilationSelector<T extends { compilation: CompilationState }>(state: T): CompilationState {
  return state.compilation;
}

export const feedCompilationSelector = createSelector(compilationSelector, ({ feed }) => feed);
export const feedIdsCompilationSelector = createSelector(compilationSelector, ({ feedIds }) => feedIds);
export const recordCompilationSelector = createSelector(compilationSelector, ({ record }) => record);
export const getByIdCompilationSelector = createSelector(
  [recordCompilationSelector, (_, id: string) => id],
  (record, id) => record[id],
);
