import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthPage, guard, ModPage, HomePage, UserPage } from '@/features';
import { Layout } from '../layout';
import { HintPage } from '@/features/hint/hint.page';
import { MemePage } from '@/features/meme/meme.page';
import { CarouselPage } from '@/features/carousel/carousel.page';
import { CompilationPage } from '@/features/compilation/compilation.page';

export const Root: FC<unknown> = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={guard(<HomePage />)} />
        <Route path="auth/*" element={<AuthPage />} />
        <Route path="user/*" element={guard(<UserPage />)} />
        <Route path="mod/*" element={guard(<ModPage />)} />
        <Route path="meme/*" element={guard(<MemePage />)} />
        <Route path="hint/*" element={guard(<HintPage />)} />
        <Route path="carousel/*" element={guard(<CarouselPage />)} />
        <Route path="compilation/*" element={guard(<CompilationPage />)} />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Route>
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
};
