import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { assoc, assocPath, compose, filter, indexBy, lensProp, over, prop } from 'ramda';
import { CompilationEntity, CompilationState } from './compilation.types';
import {
  feedCompilationAction,
  removeByIdCompilationAction,
  updateCompilationAction,
  updateSortedCompilationAction,
} from './compilation.action';

const initialState: CompilationState = {
  feed: [],
  feedIds: [],
  record: {},
};

const normalizeFeed = (state: CompilationState, result: CompilationEntity[]): CompilationState =>
  compose<[CompilationState], CompilationState, CompilationState, CompilationState>(
    assoc('feed', result),
    assoc('feedIds', result.map(prop('id'))),
    assoc('record', indexBy(prop('id'), result)),
  )(state);

export const compilationReducer = reducerWithInitialState(initialState)
  .case(feedCompilationAction.done, (state, { result }) => normalizeFeed(state, result))
  .case(removeByIdCompilationAction.done, (state, { params: compilationId }) =>
    compose<[CompilationState], CompilationState, CompilationState>(
      over(
        lensProp('feedIds'),
        filter((item: string) => item !== compilationId),
      ),
      over(
        lensProp('feed'),
        filter((item: CompilationEntity) => item.id !== compilationId),
      ),
    )(state),
  )
  .case(updateCompilationAction.done, (state, { result }) =>
    compose<[CompilationState], CompilationState, CompilationState>(
      assocPath(['record', result.id], result),
      assoc(
        'feed',
        state.feed.map((item) => (item.id === result.id ? result : item)),
      ),
    )(state),
  )
  .case(updateSortedCompilationAction.done, (state, { result }) => normalizeFeed(state, result));
