import { DragIndicator, MoreVert } from '@mui/icons-material';
import { IconButton, Link, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { byIdModSelector } from '../../mod.selector';
import { useDrag, useDrop } from 'react-dnd';
import { dropHover } from '@/utils';
import { UserRole } from '@/types';
import { useIsAccept } from '@/features/auth';

interface DndListItemProps {
  modId: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onRemove: () => void;
}

interface DragItem {
  id: string;
  index: number;
}

export const DndListItem: FC<DndListItemProps> = ({ modId, index, moveItem, onRemove }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isRemoveAccept = useIsAccept(UserRole.ADMIN);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const mod = useSelector((state) => byIdModSelector(state, modId));
  const ref = useRef<HTMLTableRowElement>(null);
  const [_, drop] = useDrop<DragItem, void>({
    accept: 'addon',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: dropHover(ref, index, moveItem),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'addon',
    item: () => {
      return { id: modId, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleMoreToggle = (): void => {
    setIsOpen((data) => !data);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleRemove = (): void => {
    handleClose();
    onRemove();
  };

  drop(preview(ref));

  return (
    <TableRow ref={ref} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell ref={drag}>
        <IconButton sx={{ cursor: 'move' }}>
          <DragIndicator />
        </IconButton>
      </TableCell>
      <TableCell>{mod.name.ru}</TableCell>
      <TableCell align="right">
        <div>
          <IconButton
            ref={anchorRef}
            aria-label="more"
            id="long-button"
            aria-controls={isOpen ? 'long-menu' : undefined}
            aria-expanded={isOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMoreToggle}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorRef.current}
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}
          >
            {isRemoveAccept ? <MenuItem onClick={handleRemove}>Удалить</MenuItem> : null}
            <MenuItem>
              <Link
                href={`/mod/edit/${modId}`}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                underline="none"
              >
                Редактировать
              </Link>
            </MenuItem>
            <MenuItem onClick={() => moveItem(index, 0)}>В самый верх</MenuItem>
            <MenuItem onClick={() => moveItem(index, -1)}>В самый низ</MenuItem>
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};
