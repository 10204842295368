import { Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { MemeForm } from './components';
import { createMemeAction } from './meme.action';
import { MemeCreatePayload } from './meme.types';

export const MemeCreatePage: FC = () => {
  const dispatch = useDispatch();

  const handleFormSubmit = (data: MemeCreatePayload): void => {
    console.log('data: %o', data);
    dispatch(createMemeAction.started(data));
  };

  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Typography sx={{ marginBottom: '2rem' }} variant="h1">
        Создание Мема
      </Typography>
      <MemeForm
        defaultValues={{
          descRu: '',
          descEn: '',
          haveAdv: false,
          // picture: null,
        }}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};
