import styled from '@emotion/styled';
import { forwardRef, useCallback, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import debounce from 'lodash.debounce';
import { Download, Crop, Close, Save } from '@mui/icons-material';
import { Button, Fab } from '@mui/material';

const ImgProfile = styled.div<{ url: string }>(({ url }) => {
  return {
    width: '30rem',
    height: '30rem',
    maxHeight: '35rem',
    background: `url(${url}) center/cover no-repeat`,
    marginBottom: '1rem',
  };
});

interface CroppableImageProps {
  onChange: (url: string) => void;
  onDelete: () => void;
  onDragEnter: () => void;
  onDragEnd: () => void;
  src: string;
  downloadUrl: string;
}

export const CroppableImage = forwardRef<HTMLInputElement, CroppableImageProps>(
  ({ src, downloadUrl, onChange, onDelete, onDragEnd, onDragEnter }) => {
    const [isCropping, setIsCropping] = useState(false);
    let [url, setUrl] = useState(src);
    const cropperRef = useRef<HTMLImageElement>(null);

    const onCrop = useCallback(
      debounce(() => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setIsCropping(false);
        url = cropper.getCroppedCanvas().toDataURL();
        setUrl(cropper.getCroppedCanvas().toDataURL());
        onChange(url);
      }, 500),
      [],
    );

    return isCropping ? (
      <div>
        <Cropper
          src={url}
          style={{ height: 350, width: 305 }}
          initialAspectRatio={16 / 9}
          guides={false}
          ref={cropperRef}
        />
        <Button onClick={onCrop} sx={{ margin: '1rem 0', width: 150 }}>
          <Save />
        </Button>
        <Button onClick={() => setIsCropping(false)} color="error" sx={{ width: 150 }}>
          <Close />
        </Button>
      </div>
    ) : (
      <ImgProfile url={url} css={{ margin: 0 }} draggable={true} onDragEnter={onDragEnter} onDragEnd={onDragEnd}>
        <Fab
          sx={{ float: 'right', margin: '1rem', zIndex: 100 }}
          href={downloadUrl}
          download={true}
          rel="noopener noreferrer"
          size="medium"
          color="primary"
        >
          <Download />
        </Fab>
        <Fab
          sx={{ float: 'right', margin: '1rem', zIndex: 100 }}
          onClick={() => setIsCropping(true)}
          rel="noopener noreferrer"
          size="medium"
          color="primary"
        >
          <Crop />
        </Fab>
        <Fab
          sx={{ float: 'right', margin: '1rem', zIndex: 100 }}
          onClick={() => onDelete()}
          rel="noopener noreferrer"
          size="medium"
          color="error"
        >
          <Close />
        </Fab>
      </ImgProfile>
    );
  },
);
