import actionCreatorFactory from 'typescript-fsa';
import {
  CompilationCreatePayload,
  CompilationEntity,
  CompilationUpdatePayload,
  SortedUpdateCompilationPayload,
} from './compilation.types';

const actionCreator = actionCreatorFactory('[COMPILATION]');

export const createCompilationAction = actionCreator.async<CompilationCreatePayload, CompilationEntity>('CREATE');
export const updateCompilationAction = actionCreator.async<CompilationUpdatePayload, CompilationEntity>('UPDATE');
export const feedCompilationAction = actionCreator.async<void, CompilationEntity[]>('FEED');
export const removeByIdCompilationAction = actionCreator.async<string, void>('REMOVE_BY_ID');
export const updateSortedCompilationAction = actionCreator.async<SortedUpdateCompilationPayload, CompilationEntity[]>(
  'UPDATE_SORTED',
);
