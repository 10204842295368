import { Option } from '@/types';
import { forwardRef, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { UploadFile } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { CroppableImage } from './croppable-image';

const maxImageUploadSize = 1e7;

interface ImageUploaderProps {
  onChange: (files: File[]) => void;
  images?: string[];
  title: string;
  fileTypes?: string[];
  error?: string;
  limit?: number;
}

export const ImageUploader = forwardRef<HTMLInputElement, ImageUploaderProps>(
  ({ fileTypes = ['JPEG', 'PNG', 'JPG'], images, title, error: defaultError, onChange, limit }, ref) => {
    let [imageUrls, setImageUrls] = useState<string[]>([]);
    let [downloadUrls, setDownloadUrls] = useState<string[]>(images || []);
    const [allowNewFiles, setAllowNewFiles] = useState(!limit || imageUrls.length < limit);
    const [error, setError] = useState<Option<string>>('');
    let lastDropEnter = -1;

    useEffect(() => {
      if (!images) return;
      (async () => {
        const loadedUrls = await Promise.all(
          images.map((url) =>
            fetch(url)
              .then((r) => r.blob())
              .then((d) => blobToDataURL(d)),
          ),
        );
        setImageUrls(loadedUrls);
      })();
    }, []);

    useEffect(() => {
      if (defaultError) {
        setError(defaultError);
      }
    }, [defaultError]);

    useEffect(() => {
      const canChange = !limit || imageUrls.length < limit;
      setAllowNewFiles(canChange);
    }, [imageUrls.length]);

    const dataURLtoFile = (dataurl: any, filename: string): File => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

    const blobToDataURL = (blob: any): Promise<string> => {
      return new Promise<string>((res) => {
        const fr = new FileReader();
        fr.onload = (e) => res(e.target?.result?.toString() || '');
        fr.readAsDataURL(blob);
      });
    };

    const handleChange = (file: File): void => {
      setError(null);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = (e: any) => {
        downloadUrls = [...downloadUrls, e.target.result];
        imageUrls = [...imageUrls, e.target.result];
        onChange(imageUrls.map((url) => dataURLtoFile(url, `${Date.now() + imageUrls.length - 1}.png`)));
        setDownloadUrls(downloadUrls);
        setImageUrls(imageUrls);
      };
    };

    return (
      <Box ref={ref} justifyContent="center">
        <Typography variant="body1" color="mainPurple">
          {title}
        </Typography>
        <Box
          css={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'start',
            flexWrap: 'wrap',
            display: 'flex',
            gap: '8px',
            margin: '8px',
          }}
        >
          {imageUrls.map((url, i) => (
            <CroppableImage
              key={url}
              src={url}
              downloadUrl={downloadUrls[i]}
              onChange={(urla) => {
                const newArr = imageUrls.map((v) => (v == url ? urla : v));
                setImageUrls(newArr);
                onChange(newArr.map((urlb) => dataURLtoFile(urlb, `${Date.now() + i}.png`)));
              }}
              onDelete={() => {
                const newArr = imageUrls.filter((_, a) => a != i);
                setImageUrls(newArr);
                onChange(newArr.map((urlb) => dataURLtoFile(urlb, `${Date.now() + i}.png`)));
              }}
              onDragEnter={() => {
                lastDropEnter = i;
              }}
              onDragEnd={() => {
                if (lastDropEnter == -1) return;
                const newArr = [...imageUrls];
                newArr[lastDropEnter] = imageUrls[i];
                newArr[i] = imageUrls[lastDropEnter];
                setImageUrls(newArr);
                imageUrls = newArr;
                lastDropEnter = -1;
              }}
            />
          ))}
          {allowNewFiles && (
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} onTypeError={setError}>
              <div
                style={{
                  width: '30rem',
                  height: '30rem',
                  border: '3px dashed rgb(6, 88, 194)',
                  borderRadius: '1rem',
                  cursor: 'pointer',
                }}
              >
                <UploadFile color="primary" sx={{ margin: 'auto', width: '100%', height: '100%', padding: '10rem' }} />
              </div>
            </FileUploader>
          )}
        </Box>
        {error ? <Typography color="red">{error}</Typography> : null}
      </Box>
    );
  },
);
