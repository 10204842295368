import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { DndListItem } from './dnd-list-item';

interface DndCompilationListProps {
  listIds: string[];
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onRemove: (id: string) => void;
}

export const DndCompilationList: FC<DndCompilationListProps> = ({ listIds, moveItem, onRemove }) => {
  const handleRemove = (removeId: string) => () => {
    onRemove(removeId);
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Потяни меня</TableCell>
            <TableCell>Название</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listIds.map((compilationId, index) => (
            <DndListItem
              key={compilationId}
              index={index}
              compilationId={compilationId}
              moveItem={moveItem}
              onRemove={handleRemove(compilationId)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
