import { CoreApi, toFormData } from '@/api';
import { Observable } from 'rxjs';
import { ModCreatePayload, ModEntity, ModUpdatePayload, SortedModResult, SortedUpdatePayload } from './mod.types';

export class ModApi extends CoreApi {
  getById(id: string): Observable<ModEntity> {
    return this.get(`/asset/${id}`);
  }

  removeById(id: string): Observable<void> {
    return this.delete(`/asset/${id}`);
  }

  create(body: ModCreatePayload): Observable<ModEntity> {
    return this.postFiles('/asset', toFormData(body, ['file']));
  }

  update({ id, data }: ModUpdatePayload): Observable<ModEntity> {
    return this.putFiles(`/asset/${id}`, toFormData(data, ['picture', 'file']));
  }

  feed(): Observable<ModEntity[]> {
    return this.get('/asset');
  }

  sortedFeed(): Observable<SortedModResult> {
    return this.get('/asset/sorted');
  }

  updateSorted({ type, ...data }: SortedUpdatePayload): Observable<void> {
    return this.put(`/asset/sorted/${type}`, data);
  }
}
