import { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { guard } from '../auth';
import { useDispatch } from 'react-redux';
import { feedModAction } from '../mod/mod.action';
import { CompilationListPage } from './compilation-list.page';
import { CompilationCreatePage } from './compilation-create.page';
import { CompilationEditPage } from './compilation-edit.page';

export const CompilationPage: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(feedModAction.started());
  }, []);
  return (
    <Routes>
      <Route index element={guard(<CompilationListPage />)} />
      <Route path="create" element={guard(<CompilationCreatePage />, { redirect: false })} />
      <Route path="edit/:compilationId" element={guard(<CompilationEditPage />, { redirect: false })} />
      <Route path="*" element={<Navigate to="/compilation" />} />
    </Routes>
  );
};
