import actionCreatorFactory from 'typescript-fsa';
import {
  MemeCreatePayload,
  MemeEntity,
  MemeUpdatePayload,
  SortedFeedMemeDone,
  SortedUpdateMemePayload,
} from './meme.types';

const actionCreator = actionCreatorFactory('[MEME]');

export const createMemeAction = actionCreator.async<MemeCreatePayload, MemeEntity>('CREATE');
export const updateMemeAction = actionCreator.async<MemeUpdatePayload, MemeEntity>('UPDATE');
export const feedMemeAction = actionCreator.async<void, MemeEntity[]>('FEED');
export const removeByIdMemeAction = actionCreator.async<string, void>('REMOVE_BY_ID');
export const sortedFeedMemeAction = actionCreator.async<void, SortedFeedMemeDone>('SORTED_FEED');
export const updateSortedMemeAction = actionCreator.async<SortedUpdateMemePayload, void>('UPDATE_SORTED');
