import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ModalRemoveProps {
  children: ReactNode;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

export const ModalRemove: FC<ModalRemoveProps> = ({ isOpen, title, children, onClose, onRemove }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-remove-title"
      aria-describedby="modal-remove-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onRemove}>
          Удалить
        </Button>
        <Button onClick={onClose} autoFocus>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
