import { Epic } from '@/types';
import { createAsyncSingleLoadingEpic } from '@ro-loading';
import { createAsyncEpic, ofActions } from '@tsfsa-ro';
import * as R from 'ramda';
import { combineEpics } from 'redux-observable';
import { filter, map } from 'rxjs';
import {
  createMemeAction,
  removeByIdMemeAction,
  sortedFeedMemeAction,
  updateMemeAction,
  updateSortedMemeAction,
} from './meme.action';
import { ModType } from '../mod/mod.types';

const propId = R.map(R.prop('id'));

const sortedFeedMemeEpic = createAsyncEpic(
  sortedFeedMemeAction,
  ({ memeApi }) =>
    () =>
      memeApi.sortedFeed().pipe(
        map((data) => {
          return {
            record: R.indexBy(R.prop('id'), [
              ...data[ModType.SKIN],
              ...data[ModType.MOD],
              ...data[ModType.SEED],
              ...data[ModType.MAP],
            ]),
            sortedIds: {
              [ModType.MOD]: propId(data[ModType.MOD]),
              [ModType.SKIN]: propId(data[ModType.SKIN]),
              [ModType.SEED]: propId(data[ModType.SEED]),
              [ModType.MAP]: propId(data[ModType.MAP]),
            },
          };
        }),
      ),
);
const createMemeEpic = createAsyncEpic(createMemeAction, ({ memeApi }) => memeApi.create.bind(memeApi));
const updateMemeEpic = createAsyncEpic(updateMemeAction, ({ memeApi }) => memeApi.update.bind(memeApi));
const updateSortedMemeEpic = createAsyncEpic(updateSortedMemeAction, ({ memeApi }) =>
  memeApi.updateSorted.bind(memeApi),
);
const removeByIdMemeEpic = createAsyncEpic(removeByIdMemeAction, ({ memeApi }) => memeApi.removeById.bind(memeApi));

const navigateToMoListAfterCreateEpic: Epic = (action$, _, { history }) =>
  action$.pipe(
    ofActions(createMemeAction.done, updateMemeAction.done),
    filter(({ result }) => {
      history.replace(`/meme`);
      return false;
    }),
  );

const sortedFeedMemeLoadingEpic = createAsyncSingleLoadingEpic(sortedFeedMemeAction, 'feedSortedMeme');
const createMemeLoadingEpic = createAsyncSingleLoadingEpic(createMemeAction, 'createMeme');

export const memeEpic = combineEpics(
  sortedFeedMemeEpic,
  createMemeEpic,
  updateMemeEpic,
  updateSortedMemeEpic,
  removeByIdMemeEpic,

  navigateToMoListAfterCreateEpic,

  sortedFeedMemeLoadingEpic,
  createMemeLoadingEpic,
);
