import { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { guard } from '../auth';
import { ModType } from '../mod/mod.types';
import { MemeListPage } from './meme-list.page';
import { MemeEditPage } from './meme-edit.page';
import { MemeCreatePage } from './meme-create.page';
import { useDispatch } from 'react-redux';
import { feedModAction } from '../mod/mod.action';

export const MemePage: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(feedModAction.started());
  }, []);
  return (
    <Routes>
      {/* <Route path="mods" element={guard(<MemeListPage type={ModType.MOD} />)} />
      <Route path="maps" element={guard(<MemeListPage type={ModType.MAP} />)} />
      <Route path="seeds" element={guard(<MemeListPage type={ModType.SEED} />)} />
      <Route path="skins" element={guard(<MemeListPage type={ModType.SKIN} />)} /> */}
      <Route path="/" element={guard(<MemeListPage />)} /> */
      <Route path="create" element={guard(<MemeCreatePage />, { redirect: false })} />
      <Route path="edit/:memeId" element={guard(<MemeEditPage />, { redirect: false })} />
      <Route path="*" element={<Navigate to="/meme/mods" />} />
    </Routes>
  );
};
