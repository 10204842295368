import { createAsyncSingleLoadingEpic } from '@ro-loading';
import { createAsyncEpic } from '@tsfsa-ro';
import { combineEpics, ofType } from 'redux-observable';
import {
  createCarouselAction,
  feedCarouselAction,
  removeByIdCarouselAction,
  updateCarouselAction,
  updateSortedCarouselAction,
} from './carousel.action';
import { filter } from 'rxjs';
import { Epic } from '@/types';

const feedCarouselEpic = createAsyncEpic(feedCarouselAction, ({ carouselApi }) => carouselApi.feed.bind(carouselApi));
const createCarouselEpic = createAsyncEpic(createCarouselAction, ({ carouselApi }) =>
  carouselApi.create.bind(carouselApi),
);
const updateCarouselEpic = createAsyncEpic(updateCarouselAction, ({ carouselApi }) =>
  carouselApi.update.bind(carouselApi),
);
const updateSortedCarouselEpic = createAsyncEpic(updateSortedCarouselAction, ({ carouselApi }) =>
  carouselApi.updateSorted.bind(carouselApi),
);
const removeByIdCarouselEpic = createAsyncEpic(removeByIdCarouselAction, ({ carouselApi }) =>
  carouselApi.removeById.bind(carouselApi),
);

const navigateToCarouselListAfterCreateUpdateEpic: Epic = (action$, _, { history }) =>
  action$.pipe(
    ofType(createCarouselAction.done.type, updateCarouselAction.done.type),
    filter(() => {
      history.replace(`/carousel`);
      return false;
    }),
  );

const sortedFeedCarouselLoadingEpic = createAsyncSingleLoadingEpic(feedCarouselAction, 'feedCarousel');
const createCarouselLoadingEpic = createAsyncSingleLoadingEpic(createCarouselAction, 'createCarousel');

export const carouselEpic = combineEpics(
  feedCarouselEpic,
  createCarouselEpic,
  updateCarouselEpic,
  updateSortedCarouselEpic,
  removeByIdCarouselEpic,

  navigateToCarouselListAfterCreateUpdateEpic,

  sortedFeedCarouselLoadingEpic,
  createCarouselLoadingEpic,
);
