import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ModalRemove } from '@/components';
import { getByIdCompilationSelector } from '../../compilation.selector';

interface RemoveModalProps {
  isOpen: boolean;
  id: string;
  onClose: () => void;
  onRemove: () => void;
}

export const RemoveModal: FC<RemoveModalProps> = ({ isOpen, id, onClose, onRemove }) => {
  const compilation = useSelector((state) => getByIdCompilationSelector(state, id));

  return (
    <ModalRemove isOpen={isOpen} title="Вы уверены что хотите удалить подборку?" onRemove={onRemove} onClose={onClose}>
      <DialogContentText id="alert-dialog-description">{compilation?.name.ru ?? id}</DialogContentText>
    </ModalRemove>
  );
};
